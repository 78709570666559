(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //header
  var header = $('header');
  var navbarMenu = $('.navbar-nav');
  var searchBox = $('.search-box');
  header.find('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
    header.toggleClass('active');
  });
  header.find('.search-item').click(function (e) {
    e.preventDefault();
    navbarMenu.hide();
    searchBox.fadeIn(700);
    searchBox.addClass('active');
    searchBox.find('input').focus();
  });
  searchBox.find('input').keyup(function () {
    searchBox.find('button').removeAttr('disabled');
    var this_val = $(this).val().length;

    if (this_val == 0) {
      searchBox.find('button').attr('disabled', 'disabled');
    }
  });
  var valid;
  searchBox.find('input').focusout(function () {
    var that = this;
    valid = this.value.length ? true : false;
    !valid && window.setTimeout(function () {
      searchBox.removeClass('active');
      searchBox.hide();
      navbarMenu.fadeIn(700);
      $(this).val('');
      searchBox.find('button').attr('disabled', true);
    }, 0);
  });
  searchBox.find('button').click(function (e) {
    if (!valid) {
      return false;
    }
  }); //footer

  var footer = $('footer');
  var footer_menu = footer.find('.col-menu');
  footer_menu.find('h4').click(function () {
    var _this = $(this);

    var _target = _this.parent('.col-menu').next();

    if (!_this.parents('.menu-wrap').hasClass('active')) {
      footer_menu.find('.menu-wrap').removeClass('active');

      _this.parents('.menu-wrap').addClass('active');
    } else {
      footer_menu.find('.menu-wrap').removeClass('active');
    }
  });
  var share_button = $('.share-button');
  share_button.find('.share-toggle').click(function (e) {
    e.preventDefault();
    share_button.find('ul').slideToggle();
  });
}

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var cookie_policy = $('.cookie-policy');

  if (!Cookies.get("cookie_policy")) {
    cookie_policy.addClass('active');
  }

  cookie_policy.find('a.button-primary').click(function (e) {
    e.preventDefault();
    cookie_policy.removeClass('active');
    Cookies.set("cookie_policy", true, {
      expires: 999
    });
  });
}

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var owlItemLength;
  var owl = $('.educations .owl-container');
  owlItemLength = owl.attr('data-item', owl.find('.education-card').length);
  var owlValue = Number(owl.attr('data-item'));

  if (owlValue >= 2) {
    owlValue = 2;
  } //education


  $('.educations .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 700,
    margin: 30,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: true,
    navText: '',
    responsive: {
      0: {
        stagePadding: 30,
        margin: 15
      },
      576: {
        items: 1,
        slideBy: 1,
        stagePadding: 0
      },
      768: {
        items: owlValue,
        slideBy: owlValue,
        stagePadding: 0
      }
    }
  });
}

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //fix popup

  /*if (!Cookies.get("popup")) {
  	var popup = $("body").data("popup");
  
  	if (popup) {
  		$.fancybox.open({
  			src  : popup,
  			type : 'ajax',
  			opts : {
  				//afterShow : function( instance, current ) {
  					//console.info( 'done!' );
  				//},
  				afterClose : function( instance, current ) {
  					Cookies.set("popup", true, {expires: 999});
  				}
  			}
  		});
  	}
  }*/
  var popup = $("body").data("popup");
  var getUrl = window.location.pathname.replace('/', '');
  var url = '/ajax/get-popup?page=' + getUrl;

  if (popup != '') {
    if (!Cookies.get("popup")) {
      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: function success(response) {
          //console.log(response.popup.image);
          if (response.status) {
            var image, figure, title, subcontent, button;

            if (response.popup.onlyImage) {
              image = '<a href="' + response.popup.button_link + '" target="_blank"><img src="' + response.popup.image + '" alt=""></a>';
            } else {
              image = '';
            }

            if (response.popup.image && !response.popup.onlyImage) {
              figure = '<figure class="bg" style="background: url(/' + response.popup.image + ')"></figure>';
            } else {
              figure = '';
            }

            if (response.popup.title) {
              title = '<h2>' + response.popup.title + '</h2>';
            } else {
              title = '';
            }

            if (response.popup.subcontent) {
              subcontent = '<p">' + response.popup.subcontent + '</p>';
            } else {
              subcontent = '';
            }

            if (response.popup.button_link && response.popup.button_text) {
              button = '<a href="' + response.popup.button_link + '" class="button-primary">' + response.popup.button_text + '</a>';
            } else {
              button = '';
            }

            if (!response.popup.youtube_link) {
              $.fancybox.open({
                src: popup,
                type: 'ajax',
                opts: {
                  afterLoad: function afterLoad(instance, current) {
                    //console.info( 'done!' );
                    $(".dynamic-popup").append(image + figure + '<section>' + title + subcontent + button + '</section>');
                  },
                  afterClose: function afterClose(instance, current) {
                    Cookies.set("popup", true, {
                      expires: 999
                    });
                  }
                }
              });
            } else {
              $.fancybox.open({
                src: response.popup.youtube_link,
                //type : 'ajax',
                opts: {
                  afterClose: function afterClose(instance, current) {
                    Cookies.set("popup", true, {
                      expires: 999
                    });
                  }
                }
              });
            }
          }
        }
      });
    }
  }
}

},{}],5:[function(require,module,exports){
"use strict";

var _common = _interopRequireDefault(require("./app/common"));

var _education = _interopRequireDefault(require("./app/education"));

var _popup = _interopRequireDefault(require("./app/popup"));

var _cookie = _interopRequireDefault(require("./app/cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

$(document).ready(function () {
  (0, _common["default"])();
  (0, _education["default"])();
  (0, _popup["default"])();
  (0, _cookie["default"])(); //popular-content

  $('.popular-content .owl-carousel').owlCarousel({
    items: 1,
    nav: false,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 700,
    margin: 30,
    loop: false,
    mouseDrag: false,
    responsive: {
      0: {
        stagePadding: 30,
        margin: 15
      },
      576: {
        items: 1,
        slideBy: 1
      },
      768: {
        items: 2,
        slideBy: 2
      }
    }
  });
  resize();
  $(window).resize(function () {
    resize();
  });
});

function resize() {
  var windowHeight = $(window).height();
  var hero = $('.hero__video figure');
  var headerHeight = $('header').height();
  hero.height(windowHeight - headerHeight + 16);
}

},{"./app/common":1,"./app/cookie":2,"./app/education":3,"./app/popup":4}]},{},[5]);
